import * as React from "react";

type Props = {
  color?: string;
};

export default function GleanIcon({ color = "currentColor" }: Props) {
  return (
    <svg
      fill={color}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.42 4.41301C15.906 4.79101 16.317 5.11501 16.332 5.13001C16.35 5.14801 16.08 5.53201 15.642 6.10801C15.522 6.2683 15.4074 6.42104 15.3056 6.55658L15.3053 6.55699L15.3052 6.55715C15.0739 6.86525 14.9094 7.08435 14.901 7.10101C14.886 7.12801 14.913 7.18501 14.979 7.26901C15.282 7.63801 15.651 8.32501 15.834 8.85301C16.65 11.193 15.765 13.818 13.695 15.198C12.789 15.801 11.772 16.107 10.68 16.11C9.99603 16.11 9.53403 16.038 8.92503 15.837C6.97803 15.189 5.56203 13.518 5.23203 11.475C5.16903 11.082 5.16003 10.284 5.21703 9.90001C5.54403 7.62001 7.22403 5.79601 9.45303 5.29801C10.53 5.05801 11.664 5.14801 12.669 5.55001C12.834 5.61601 12.975 5.67001 12.99 5.67001C13.002 5.67001 13.341 5.23201 13.743 4.69801C14.145 4.16101 14.49 3.72301 14.505 3.72301C14.523 3.72001 14.934 4.03201 15.42 4.41301ZM17.586 15.525L18.51 16.206L18.444 16.302C18.282 16.533 17.838 17.052 17.565 17.325C16.197 18.699 14.457 19.623 12.525 20.007C11.826 20.148 11.517 20.175 10.665 20.175C9.74403 20.175 9.22503 20.121 8.48403 19.947C8.27703 19.899 8.22003 19.875 8.22003 19.833C8.22003 19.764 8.74803 17.643 8.76903 17.619C8.77803 17.61 8.97303 17.637 9.20103 17.682C9.80403 17.796 10.065 17.82 10.635 17.82C12.27 17.82 13.788 17.325 15.042 16.383C15.594 15.966 16.206 15.36 16.485 14.952C16.539 14.874 16.593 14.823 16.62 14.829C16.644 14.835 17.079 15.147 17.586 15.525ZM10.29 7.53301C8.79003 7.70401 7.62303 8.95201 7.54203 10.47C7.44602 12.249 8.85603 13.74 10.635 13.74C12.132 13.74 13.3681 12.744 13.7011 11.265C13.7671 10.968 13.7671 10.317 13.6981 10.005C13.4041 8.66101 12.354 7.69501 11.01 7.53001C10.725 7.49701 10.614 7.49701 10.29 7.53301Z"
      />
    </svg>
  );
}
